import { H1, PStyled } from "@mc/components/core/typography.styles";
import { ModalCenteredContent } from "@mc/components/modal/modal.styles";
import { Margin } from "@mc/components/core/spacing.styles";
import { ButtonPrimary, PrimaryLoadingButton } from "@mc/components/buttons";
import { Modal, useModalState } from "@mc/components/modal/modal";
import { useMutation } from "@apollo/client";
import { APPROVE_PURCHASE_TRACKER_STAGE_OR_ITEM } from "@mc/web/src/purchase-tracker/purchase-tracker.graphql";
import { useNotification } from "@mc/components/notification/notification.context";
import {
  ApprovePurchaseTrackerStageOrItemMutation,
  ApprovePurchaseTrackerStageOrItemMutationVariables,
  PurchaseTrackerItemStatus,
  PurchaseTrackerStageType,
  SaleOfferType,
} from "@mc/common/gql/types";

type Props = {
  stageType: PurchaseTrackerStageType;
  saleOfferType: SaleOfferType | undefined | null;
  shortlistPropertyId: string;
};

const PurchaseTrackerApproval = ({ stageType, saleOfferType, shortlistPropertyId }: Props) => {
  const { showSuccess, showError } = useNotification();
  const { isVisible, open, close } = useModalState();

  const [approve, { loading }] = useMutation<
    ApprovePurchaseTrackerStageOrItemMutation,
    ApprovePurchaseTrackerStageOrItemMutationVariables
  >(APPROVE_PURCHASE_TRACKER_STAGE_OR_ITEM, {
    variables: {
      shortlistPropertyId,
      itemStatus: PurchaseTrackerItemStatus.COMPLETE,
      stageType,
    },
    onCompleted: () => {
      close();
      showSuccess("Your approval has been submitted");
      window.location.reload();
    },
    onError: () => {
      showError("Oh no, something went wrong. Please try again later.");
    },
  });

  return (
    <>
      {isVisible && (
        <Modal isVisible close={close}>
          <H1>Approve and proceed?</H1>
          {stageType === PurchaseTrackerStageType.REVIEW ? (
            <Margin bottom={64}>
              <PStyled>
                At this stage, we are happy with the initial due diligence for this property and are recommending
                proceeding.
              </PStyled>
              <PStyled>
                The next stage is detailed research. We roll up our sleeves and take a deep dive into topics like local
                amenities, schools, DA&apos;s, risk considerations and more.
              </PStyled>
              <PStyled>Essentially, everything to get you into a position to proceed in making an offer.</PStyled>
              <PStyled>So what do you think?</PStyled>
            </Margin>
          ) : saleOfferType === SaleOfferType.UNCONDITIONAL ? (
            <Margin bottom={64}>
              <PStyled>
                We&apos;re getting closer to securing this property for you. If all of the research we have presented in
                this stage has stacked up for you, then we&apos;re cooking with gas!
              </PStyled>
              <PStyled>
                Upon proceeding, we will be reviewing the recommended pre-purchase reports commissioned (if any), the
                contract of sale and getting you in a position to purchase this property!
              </PStyled>
            </Margin>
          ) : (
            <Margin bottom={64}>
              <PStyled>
                We&apos;re getting closer to securing this property for you. If all of the research we have presented in
                this stage has stacked up for you, then we&apos;re cooking with gas!
              </PStyled>
              <PStyled>
                Upon proceeding, we will get you in a position to make a conditional offer for this property! Don&apos;t
                worry, we can take care of the recommended pre-purchase reports (if any) and the contract of sale
                reviews if the offer is accepted, afterward.
              </PStyled>
            </Margin>
          )}

          <ModalCenteredContent>
            <PrimaryLoadingButton type="button" isLoading={loading} onClick={() => approve()}>
              Approve and proceed
            </PrimaryLoadingButton>
          </ModalCenteredContent>
        </Modal>
      )}
      <ButtonPrimary type="button" onClick={() => open()} $width="100%">
        Approve and proceed
      </ButtonPrimary>
    </>
  );
};

export default PurchaseTrackerApproval;
