import { gql } from "@apollo/client";
import { TIMELINE_FRAGMENT } from "shared/graphql/timeline.graphql";

export const GET_TIMELINE = gql`
  query GetTimeline {
    me {
      _id
      firstName
      gamePlan {
        _id
        chosenOption {
          _id
          timeline {
            items {
              ...TimelineFragment
            }
          }
        }
      }
    }
  }
  ${TIMELINE_FRAGMENT}
`;

const SHORTLIST_PROPERTY_CLIENT_FRAGMENT = gql`
  fragment ShortlistPropertyClientFragment on ShortlistProperty {
    _id
    status
    isOffMarket
    inspectionDate {
      origin
      formatted(format: "ddd DD MMM YYYY")
    }
    details {
      id
      otmSales {
        id
        forSalePropertyCampaign {
          activeCampaign {
            price
            advertisementId
            auctionDate
            auctionTime
            daysOnMarket
            listingMethod
          }
        }
      }
      images {
        id
        defaultImage {
          basePhotoUrl
          largePhotoUrl
        }
        secondaryImageList {
          largePhotoUrl
        }
        floorPlanImageList {
          largePhotoUrl
        }
      }
      coreAttributes {
        id
        baths
        beds
        carSpaces
        landArea
        propertySubType
      }
      additionalAttributes {
        id
        yearBuilt
      }
      location {
        id
        latitude
        longitude
        locality {
          name
        }
        singleLine
        street {
          nameAndNumber
        }
      }
      lastSales {
        id
        lastSale {
          zoneDescriptionLocal
        }
      }
    }
  }
`;

export const GET_PURCHASE_SEARCH_TRACKER = gql`
  query GetPurchaseSearchTracker($purchaseId: String!) {
    purchase(purchaseId: $purchaseId) {
      _id
      topResults: shortlistProperties(status: TOP_RESULT) {
        ...ShortlistPropertyClientFragment
      }
      shortlist: shortlistProperties(status: SHORT_LIST) {
        ...ShortlistPropertyClientFragment
      }
      rejected: shortlistProperties(status: REJECTED) {
        ...ShortlistPropertyClientFragment
      }
      forFurtherConsideration: shortlistProperties(status: FOR_FURTHER_CONSIDERATION) {
        ...ShortlistPropertyClientFragment
      }
    }
  }
  ${SHORTLIST_PROPERTY_CLIENT_FRAGMENT}
`;

export const GET_PURCHASE_TRACKER_STAGES_CLIENT = gql`
  query GetPurchaseTrackerStagesClient($purchaseId: String!, $shortlistPropertyId: String!) {
    purchase(purchaseId: $purchaseId) {
      _id
      stages {
        type
        items {
          type
          label
          status
          slug
        }
      }
      shortlistProperty(shortlistPropertyId: $shortlistPropertyId) {
        _id
        stages {
          type
          items {
            slug
            label
            type
            status
            published
          }
        }
      }
    }
  }
`;

export const GET_PURCHASE_STAGES = gql`
  query GetPurchaseStages($purchaseId: String!) {
    purchase(purchaseId: $purchaseId) {
      _id
      stages {
        type
        items {
          type
          label
          status
          slug
        }
      }
    }
  }
`;
