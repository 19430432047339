import {
  GamePlanEventType,
  GetTimelineQuery,
  PurchaseTrackerItemStatus,
  PurchaseTrackerItemType,
} from "@mc/common/gql/types";
import GamePlanExistingProperties from "game-plan/game-plan-existing-properties";
import { useEffect, useState } from "react";
import Layout from "shared/layout/layout";
import { LayoutContentStyled } from "shared/layout/layout.styles";
import TimelineProjectModal from "shared/timeline/timeline-project-modal";
import TimelinePurchaseModal from "shared/timeline/timeline-purchase-modal";
import { Bouncer } from "@mc/components/bouncer/bouncer";
import { Margin } from "@mc/components/core/spacing.styles";
import { H3, PStyled, SubheadLabel } from "@mc/components/core/typography.styles";
import { Col, Container, Row } from "@mc/components/grid/grid.styles";
import { useModalState } from "@mc/components/modal/modal";
import { Timeline } from "@mc/components/timeline/timeline";
import { Stack } from "@mc/components/stack/stack.styles";
import { Header } from "./search-tracker.styles";
import { useQuery } from "@apollo/client";
import { GET_TIMELINE } from "./search-tracker.graphql";
import HeroSection from "shared/heroSection/hero-section";
import { MdOutlineViewTimeline } from "react-icons/md";
import TimelineSaleModal from "shared/timeline/timeline-sale-modal";

const SearchTrackerHome = () => {
  const { loading, data } = useQuery<GetTimelineQuery>(GET_TIMELINE);

  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);

  const { isVisible: isPurchaseVisible, open: openPurchase, close: closePurchase } = useModalState();
  const { isVisible: isProjectVisible, open: openProject, close: closeProject } = useModalState();
  const { isVisible: isSaleVisible, open: openSale, close: closeSale } = useModalState();

  const firstPurchase = data?.me.gamePlan?.chosenOption?.timeline.items
    .find((item) => item.events.some((event) => event.type === GamePlanEventType.PROPERTY_PURCHASE))
    ?.events.find((event) => event.type === GamePlanEventType.PROPERTY_PURCHASE);

  useEffect(() => {
    const src = "https://tally.so/widgets/embed.js";
    const script = document.createElement("script");
    script.setAttribute("src", src);
    script.setAttribute("type", "text/javascript");
    document.getElementsByTagName("head")[0].appendChild(script);
    script.addEventListener("load", () => {
      // @ts-ignore
      Tally.loadEmbeds();
    });

    return () => {
      script.remove();
    };
  }, [firstPurchase]);

  if (loading)
    return (
      <Margin top={64}>
        <Bouncer />
      </Margin>
    );

  if (!data) return null;

  const timelineItems = data.me.gamePlan?.chosenOption?.timeline.items;

  const isFamilyHomePendingBriefRequirements = data.me.gamePlan?.chosenOption?.timeline.items.some((item) =>
    item.events?.[0]?.purchase?.stages?.[0].items.find(
      (item) =>
        item.type === PurchaseTrackerItemType.BRIEF_REQUIREMENTS &&
        item.status === PurchaseTrackerItemStatus.ACTIVE_REQUIRED_ACTION
    )
  );

  const isFamilyHomeFinishedBriefRequirements = data.me.gamePlan?.chosenOption?.timeline.items.some(
    (item) =>
      item.events?.[0]?.purchase?.stages?.[0].items.find(
        (item) =>
          item.type === PurchaseTrackerItemType.BRIEF_REQUIREMENTS && item.status === PurchaseTrackerItemStatus.COMPLETE
      ) &&
      item.events?.[0]?.purchase?.stages?.[0].items.find(
        (item) => item.type === PurchaseTrackerItemType.YOUR_SUBURBS && !item.published
      )
  );

  return (
    <Layout paddingTop={0} hasGetInTouchFooter>
      {isFamilyHomePendingBriefRequirements && (
        <Margin top={64}>
          <HeroSection
            title={`Hi there, ${data.me.firstName}`}
            paragraph={[
              "Now that we understand the economics of your brief, we need to take a deeper dive into your home and location preferences.",
              "This shouldn't take too long and you can save and close at any point to resume later where you left off.",
            ]}
            marginTop={0}
          />
        </Margin>
      )}

      {(firstPurchase?.purchase?.createdAt.daysFromNow ?? 15) <= 14 && (
        <Container>
          <iframe
            data-tally-src="https://tally.so/embed/wzZ5xE?hideTitle=1&transparentBackground=1&dynamicHeight=1"
            loading="lazy"
            width="100%"
            height="250"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            title="NPS - Game Plan"
          ></iframe>
        </Container>
      )}

      {isFamilyHomeFinishedBriefRequirements && (
        <Margin top={64}>
          <HeroSection
            title={`Thanks, ${data.me.firstName}`}
            paragraph={["Thanks for taking the time to share all that important information with us."]}
          />

          <Container>
            <Row centerXs>
              <Col md={6}>
                <Margin top={40}>
                  <H3>What happens now?</H3>
                </Margin>
                <PStyled>
                  Our purchasing team is now reviewing the information you have provided in more detail, and we will
                  inform you if we have any further questions.
                </PStyled>
                <PStyled>
                  Once we are aligned, we will provide you with our suburb recommendations along with supporting data.
                  If you have provided any suburbs, you will be able to compare them with our recommendations.
                </PStyled>
                <PStyled>
                  You will then be required to select your preferred suburbs, and we will then begin the search for your
                  home!
                </PStyled>
              </Col>
            </Row>
          </Container>
        </Margin>
      )}

      {isPurchaseVisible && <TimelinePurchaseModal selectedEventId={selectedEventId} close={closePurchase} />}
      {isProjectVisible && <TimelineProjectModal selectedEventId={selectedEventId} close={closeProject} />}
      {isSaleVisible && <TimelineSaleModal selectedEventId={selectedEventId} close={closeSale} />}

      <Header>
        <Container>
          <Row centerXs>
            <Col md={6}>
              <Stack direction="horizontal" spacing={8} marginBottom={16}>
                <MdOutlineViewTimeline size={24} />
                <SubheadLabel marginBottom={0}>Game Plan</SubheadLabel>
              </Stack>
              <Timeline
                timeline={timelineItems || []}
                onEventClick={(event) => {
                  setSelectedEventId(event._id);

                  if (event.type === GamePlanEventType.PROJECT) {
                    openProject();
                  } else if (event.type === GamePlanEventType.PROPERTY_PURCHASE) {
                    openPurchase();
                  } else if (event.type === GamePlanEventType.PROPERTY_SALE) {
                    openSale();
                  }
                }}
              />
            </Col>
          </Row>
        </Container>
      </Header>

      <LayoutContentStyled>
        <Container>
          <Row centerXs>
            <Col md={6}>
              <GamePlanExistingProperties />
            </Col>
          </Row>
        </Container>
      </LayoutContentStyled>
    </Layout>
  );
};

export default SearchTrackerHome;
