import { H3 } from "@mc/components/core/typography.styles";
import { DataList } from "@mc/components/tables/data-list";
import { SaleDetailsFragmentFragment } from "../../../gql/types";

type Props = {
  sale: SaleDetailsFragmentFragment | null | undefined;
};

export default function SaleDetails({ sale }: Props) {
  return (
    <div>
      <H3 marginBottom={12}>Sale details</H3>

      <DataList
        data={[
          {
            label: "Sale price",
            value: sale?.price.formatted,
          },
          {
            label: "Funds available",
            value: sale?.fundsAvailable.formatted,
          },
        ]}
      />
    </div>
  );
}
