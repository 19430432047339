import { gql } from "@apollo/client";

export const SALE_DETAILS_FRAGMENT = gql`
  fragment SaleDetailsFragment on GamePlanSale {
    _id
    price {
      formatted
    }
    fundsAvailable {
      formatted
    }
  }
`;
