import { gql } from "@apollo/client";

export const PURCHASE_DETAILS_FRAGMENT = gql`
  fragment PurchaseDetailsFragment on GamePlanProperty {
    _id
    location
    purchaseType
    propertyType
    budget {
      formatted
    }
    loan {
      _id
      originalAmount {
        formatted
      }
    }
    depositAmount {
      formatted
    }
    purchaseCosts {
      adjustmentsAtSettlement {
        formatted
      }
      bankFees {
        formatted
      }
      buildingAndPest {
        formatted
      }
      crystalClean {
        formatted
      }
      electricalSafetyAssessment {
        formatted
      }
      firstHomeBuyersGrant {
        formatted
      }
      foreignBuyersDuty {
        formatted
      }
      insurance {
        formatted
      }
      invasivePlumbingAssessment {
        formatted
      }
      legal {
        formatted
      }
      lettingFee {
        formatted
      }
      maintenanceCost {
        formatted
      }
      milkChocolateFeeTotal {
        formatted
      }
      milkChocolateMaintenanceFee {
        formatted
      }
      qldRegistrationFee {
        formatted
      }
      stampDuty {
        formatted
      }
      strataReport {
        formatted
      }
      structuralEngineeringReport {
        formatted
      }
      taxDepreciationSchedule {
        formatted
      }
      termiteManagementSystem {
        formatted
      }
      subtotal {
        formatted
      }
      total {
        formatted
      }
    }
  }
`;
