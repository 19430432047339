import { gql } from "@apollo/client";

export const TIMELINE_FRAGMENT = gql`
  fragment TimelineFragment on GamePlanTimeLineItem {
    year
    events {
      _id
      endAt {
        year: formatted(format: "YYYY")
      }
      label
      type
      purchase {
        _id
        status
        createdAt {
          daysFromNow
        }
        stages {
          number
          type
          label
          published
          items {
            label
            slug
            status
            type
            published
          }
        }
        topResults: shortlistProperties(status: TOP_RESULT) {
          _id
          saleOfferType
          details {
            id
            location {
              id
              singleLine
            }
            images {
              id
              defaultImage {
                mediumPhotoUrl
              }
            }
          }
          domainListing {
            id
            bedrooms
            bathrooms
            carspaces
            landAreaSqm
            addressParts {
              displayAddress
            }
            media {
              url
            }
          }
          stages {
            number
            type
            label
            published
            items {
              label
              slug
              status
              type
              published
            }
          }
        }
        agreement {
          _id
          stage
          status
          changingGamePlan
          changingPropertyBrief
          termsAndConditions
        }
      }
      property {
        _id
        purchaseType
        propertyType
        location
        budget {
          formatted
        }
        loan {
          _id
          originalAmount {
            formatted
          }
        }
        deposit {
          value
          formatted
        }
        depositAmount {
          formatted
        }
        description
      }
      project {
        _id
        type
        propertyId
        budget {
          formatted
        }
        budgetWithCPI {
          formatted
        }
        deposit {
          value
          formatted
        }
        depositAmount {
          formatted
        }
        assumedROI {
          value
          formatted(isRatio: true)
        }
        postRenoIncreaseValue {
          formatted
        }
        loan {
          _id
          loanType
          originalAmount {
            formatted
          }
        }
      }
      sale {
        _id
        price {
          formatted
        }
        propertyId
      }
      loanAdjustment {
        _id
        type
        propertyId
        fund {
          formatted
        }
      }
      startAt {
        formatted
      }
      endAt {
        formatted
      }
    }
  }
`;
