import { H3 } from "@mc/components/core/typography.styles";
import { DataList } from "@mc/components/tables/data-list";
import TooltipInfoModal from "@mc/components/tooltips/tooltip-info-modal";
import { PurchaseDetailsFragmentFragment } from "@mc/common/gql/types";
import { PURCHASE_DETAILS_HELP_CONTENT } from "./purchase-details.constants";
import { gamePlanPurchaseTypeMap, propertyTypeMap } from "../../../utils/enum-maps";

type Props = {
  property: PurchaseDetailsFragmentFragment | null | undefined;
};
const PurchaseDetails = ({ property }: Props) => {
  return (
    <div>
      <H3 marginBottom={12}>
        Purchase details <TooltipInfoModal text={PURCHASE_DETAILS_HELP_CONTENT} />
      </H3>

      <DataList
        data={[
          {
            label: "State",
            value: property?.location ?? "N/A",
          },
          {
            label: "Purchase type",
            value: property?.purchaseType ? gamePlanPurchaseTypeMap[property.purchaseType].label : "N/A",
          },
          {
            label: "Property type",
            value: property?.propertyType ? propertyTypeMap[property.propertyType].label : "N/A",
          },
          { label: "Purchase budget", value: property?.budget.formatted },
          { label: "Loan", value: property?.loan?.originalAmount?.formatted },
          { label: "Deposit", value: property?.depositAmount.formatted },
          {
            label: "Purchase fees",
            value: property?.purchaseCosts?.subtotal.formatted,
            subValues: {
              defaultCollapsed: true,
              values: [
                { label: "First home buyers grant", value: property?.purchaseCosts?.firstHomeBuyersGrant.formatted },
                { label: "Stamp duty", value: property?.purchaseCosts?.stampDuty.formatted },
                { label: "Foreign buyers duty", value: property?.purchaseCosts?.foreignBuyersDuty.formatted },
                { label: "Bank fees", value: property?.purchaseCosts?.bankFees.formatted },
                { label: "Legal", value: property?.purchaseCosts?.legal.formatted },
                {
                  label: "Adjustments at settlement",
                  value: property?.purchaseCosts?.adjustmentsAtSettlement.formatted,
                },
                { label: "Insurance", value: property?.purchaseCosts?.insurance.formatted },
                { label: "Building and pest", value: property?.purchaseCosts?.buildingAndPest.formatted },
                { label: "Strata report", value: property?.purchaseCosts?.strataReport.formatted },
                {
                  label: "Invasive plumbing assessment",
                  value: property?.purchaseCosts?.invasivePlumbingAssessment.formatted,
                },
                {
                  label: "Electrical safety assessment",
                  value: property?.purchaseCosts?.electricalSafetyAssessment.formatted,
                },
                {
                  label: "Structural engineer report",
                  value: property?.purchaseCosts?.structuralEngineeringReport.formatted,
                },
                {
                  label: "Termite management system",
                  value: property?.purchaseCosts?.termiteManagementSystem.formatted,
                },
                {
                  label: "Letting fee and advertising",
                  value: property?.purchaseCosts?.lettingFee.formatted,
                },
                { label: "Crystal clean", value: property?.purchaseCosts?.crystalClean.formatted },
                { label: "Maintenance cost", value: property?.purchaseCosts?.maintenanceCost.formatted },
                {
                  label: "Milk Chocolate maintenance fee",
                  value: property?.purchaseCosts?.milkChocolateMaintenanceFee.formatted,
                },
                { label: "Milk Chocolate fee total", value: property?.purchaseCosts?.milkChocolateFeeTotal.formatted },
                { label: "QLD registration fee", value: property?.purchaseCosts?.qldRegistrationFee.formatted },
                {
                  label: "Tax depreciation schedule",
                  value: property?.purchaseCosts?.taxDepreciationSchedule.formatted,
                },
              ],
            },
          },
          { label: "FUNDS REQUIRED", value: property?.purchaseCosts?.total.formatted, isEmphasised: true },
        ]}
      />
    </div>
  );
};

export default PurchaseDetails;
