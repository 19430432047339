import { PurchaseTrackerItemStatus, SaleOfferType } from "@mc/common/gql/types";
import { StepAccordionToggle, StepItem, StepList } from "@mc/components/step-list/step-list";
import { Margin } from "../core/spacing.styles";
import { AnchorOriginalStyled, PStyled } from "../core/typography.styles";
import PurchaseTrackerApproval from "./purchase-tracker-approval";
import { Stage } from "./timeline.constants";
import { PurchaseTrackerTimelineContainer } from "./timeline.styles";
import { getPurchaseTrackerTimelineLink } from "./timeline.utils";

export type Props = {
  stages: Stage[];
  saleOfferType: SaleOfferType | undefined | null;
  purchaseId: string;
  shortlistPropertyId: string;
  // propertyId: string;
  isAdmin: boolean;
};

const PurchaseTrackerTimeline = ({
  stages,
  saleOfferType,
  purchaseId,
  shortlistPropertyId,
  // propertyId,
  isAdmin,
}: Props) => {
  const completedStages = stages.filter(
    (stage) => stage.items.length && stage.items.every((item) => item.status === PurchaseTrackerItemStatus.COMPLETE)
  );

  const stageNumberForApproval = completedStages.length + 2;

  return (
    <PurchaseTrackerTimelineContainer>
      {stages?.map((stage) => (
        <StepAccordionToggle
          defaultExpanded={stage.number === stageNumberForApproval}
          key={stage.label}
          title={`Stage ${stage?.number} - ${stage?.label}`}
          hasBorderBottom={true}
        >
          <Margin bottom={24}>
            <StepList>
              {stage.items.map((item) => (
                <StepItem
                  key={item.type}
                  purchaseTrackerItemStatus={item.status}
                  extraText={
                    item.status === PurchaseTrackerItemStatus.ACTIVE_REQUIRED_ACTION
                      ? isAdmin
                        ? "Client action required"
                        : "Action required"
                      : ""
                  }
                >
                  {isAdmin || item.published ? (
                    <AnchorOriginalStyled
                      $fontWeight="normal"
                      href={getPurchaseTrackerTimelineLink(isAdmin, purchaseId, shortlistPropertyId, item.slug)}
                    >
                      {item.label}
                    </AnchorOriginalStyled>
                  ) : (
                    <PStyled marginBottom={0}>{item.label}</PStyled>
                  )}
                </StepItem>
              ))}
            </StepList>
          </Margin>

          {!isAdmin &&
            stage.published &&
            [2, 3].includes(stageNumberForApproval) &&
            stage.number === stageNumberForApproval && (
              <PurchaseTrackerApproval
                shortlistPropertyId={shortlistPropertyId}
                stageType={stage.type}
                saleOfferType={saleOfferType}
              />
            )}
        </StepAccordionToggle>
      ))}
    </PurchaseTrackerTimelineContainer>
  );
};

export default PurchaseTrackerTimeline;
