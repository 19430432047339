import { gql } from "@apollo/client";
import { EXISTING_PROPERTIES_FRAGMENT } from "@mc/common/blocks/onboarding/existing-properties/existing-properties.gql";
import { PERSONAL_DETAILS_PROFILE_FRAGMENT } from "@mc/common/blocks/onboarding/personal-details/personal-details.gql";
import { INVESTMENT_GOALS_FRAGMENT } from "@mc/common/blocks/onboarding/investment-goals/investment-goals.gql";
import { HOME_PURCHASE_DETAILS_PROFILE_FRAGMENT } from "@mc/common/blocks/onboarding/home-purchase-details/home-purchase-details.gql";
import { OTHER_REQUIREMENTS_FRAGMENT } from "@mc/common/blocks/onboarding/other-requirements/other-requirements.gql";

export const USER_DRAFT_PROFILE_FRAGMENT = gql`
  fragment UserDraftProfileFragment on Profile {
    _id
    ...PersonalDetailsProfileFragment
    ...ExistingPropertiesFragment
    ...InvestmentGoalsFragment
    ...HomePurchaseDetailsProfileFragment
    ...OtherRequirementsFragment
    services
    status
    callbackURL
    updatedAt {
      origin
    }
    agreement {
      changingGamePlan
      changingPropertyBrief
      informationClause
      termsAndConditions
    }
    isFamilyHomePurchasedFirst
  }
  ${PERSONAL_DETAILS_PROFILE_FRAGMENT}
  ${EXISTING_PROPERTIES_FRAGMENT}
  ${INVESTMENT_GOALS_FRAGMENT}
  ${HOME_PURCHASE_DETAILS_PROFILE_FRAGMENT}
  ${OTHER_REQUIREMENTS_FRAGMENT}
`;

export const USER_DETAILS_FRAGMENT = gql`
  fragment UserDetailsFragment on Client {
    _id
    firstName
    lastName
    email
    countryCode
    isCitizenOrPR
    mobile
    maskedMobile
    currentLocation
    returnYear
    address {
      line1
      line2
      suburb
      state
      postcode
      country
    }
    isEmailVerified
    currentProfileStatus
    activeProfile: profile(status: ACTIVE) {
      _id
      services
      isFamilyHomePurchasedFirst
    }
    status
  }
`;

export const ME = gql`
  query Me {
    me {
      ...UserDetailsFragment
    }
  }
  ${USER_DETAILS_FRAGMENT}
`;

export const UPDATE_ME = gql`
  mutation UpdateMe($input: UpdateClientInput!) {
    updateMe(updateClientInput: $input) {
      ...UserDetailsFragment
    }
  }
  ${USER_DETAILS_FRAGMENT}
`;
