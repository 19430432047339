export function getSearchTrackerTimelineLink(isAdmin: boolean, purchaseId: string, slug: string): string {
  if (isAdmin) {
    return `properties/purchase/${purchaseId}/${slug}`;
  } else {
    return `purchase/${purchaseId}/${slug}`;
  }
}

export function getPurchaseTrackerTimelineLink(
  isAdmin: boolean,
  purchaseId: string,
  shortlistPropertyId: string,
  slug: string
): string {
  if (isAdmin) {
    return `properties/purchase/${purchaseId}/property/${shortlistPropertyId}/${slug}`;
  } else {
    return `purchase/${purchaseId}/property/${shortlistPropertyId}/${slug}`;
  }
}
